import React from 'react'
import { Button, Radio, Badge, Avatar, Typography } from 'antd'
import { Grid } from '@material-ui/core'
import Display from '../assets/bslogo.jpg'
import avemlogo from '../assets/aveminsquare.BMP'
import avemwords from '../assets/avemwithwords.JPG'
import Confetti from 'react-confetti'
import { MediaQuery } from 'react-responsive'
const { Text } = Typography


export const Logo = () => (
	<div style={{padding:0}}>
		<Confetti height={100}/>
		 <MediaQuery minDeviceWidth ={1440}> 
		<Grid container justify="center">
			<Grid>
				<a href="/">
					<img
						src= {avemlogo}
						alt="logo"
						style={{ width: 80, height: 80, paddingRight: 10, display: 'inline' }}
					/>
					<Confetti height={100} />
					<h1 style={{ fontWeight: 700, display: 'inline' }}>Added Value Engineering and Management Consultants Pte Ltd</h1>
				</a>
			</Grid>
		</Grid>
		</MediaQuery> 

		<MediaQuery maxDeviceWidth ={1439}> 
	
		<img
						src= {avemwords}
						alt="logo"
						style={{
							width:"100%",
							justifyContent: "center",
							alignItems: "center"
						  }}
					/>
		</MediaQuery>

	</div>
)
import React, { Component } from 'react'
import { Typography,Badge, Avatar, PageHeader, Divider, Carousel } from 'antd'
import { Grid } from '@material-ui/core'
import Display from '../assets/bslogo.jpg'
import { sizing } from '@material-ui/system';

//images import'
import anniv from '../assets/20yearanniv.JPG'
import chariot from '../assets/Chariots of fire.wav'
import bizsafe from '../assets/bizsafe1.PNG'
import image1 from '../assets/safetystructure186x162.jpg'


//backgroundImage:`url(${image1}

export const Home = () => (
	
	<div>
		
		<audio autoplay="autoplay">
     		<source src={chariot} />     
 		</audio>
		
			
				<img
					src= {bizsafe}
					alt="logo"
					width="100%"
					
				/>
		<br />


		
	<div style={{backgroundColor : "silver",paddingLeft:"2%",paddingRight:"2%", paddingTop:"2%"}}>
			
				<img
						src= {image1}
						alt="logo"
						Align = "left"
						style={{paddingRight:"2%"}}
				/>
				<h2 ><b>What we do</b></h2>
				We have been established since 1997. 
				We have a group of dedicated professionals whose aim is to assist small and medium enterprises (SME)
			 	and Multinational Corporations in Singapore to maintain a high Environment, 
				Health and Safety standard which in turn prevent properties and human lives losses due to industrial accident and fire disasters. 
				Environment, Health and Safety standard which in turn prevent properties and human lives losses due to industrial accident and fire disasters. 
				We can offer your company a wide range of safety consultancy and safety officer services that cater for your company's Environmental, 
				Health and Safety needs. We can also help your company to plan for an Emergency Response Preparedness and Business Continuity Plan so that your company can be least interrupted 
				in unexpected times.
		<br />
		<br />
	</div>
		
		

		<div style={{backgroundColor: "gold", padding: "2%"}}>

		

		<h2><b>Mission Statement</b></h2>
		We are committed to help others to improve Workplace Safety & Health standards. We always strive to preserve the properties and protect human lives and ensure a smooth and profitable operation of our customer’s business without compromise Workplace Safety & Health.
		<br />
		<br />
		</div>


		<div style={{backgroundColor: "lightgreen", padding:"2%"}}>
		<h2 ><b>Vision Statement </b></h2>
		To be an internationally recognized brand name for Safety Related Services and Safety Solution Provider for all industries
		</div>
		
	</div>

	
)

import React, { Component } from 'react'
import { List, Avatar, Typography, Card, Icon, Input } from 'antd'
import { Carousel } from 'antd';
import { Grid } from '@material-ui/core'

//images import
import construction from '../assets/4th Page Construction Industry.jpg'
import manufacturing from '../assets/7th Page Manufacturing Industry1.JPG'
import oilandgas from '../assets/9th Page OIL AND GAS INDUSTRY.JPG'
import shipbuilding from '../assets/11th Page Ship Building Ind01.JPG'
import lifting from '../assets/Lifting02.JPG'
import safetytraining from '../assets/Safety Training01.JPG'
import firedrill from '../assets/Fire Drill01.JPG'
import drone from '../assets/droneinspection.JPG'
import safesong from '../assets/safesong.mp3'
import skylimit from '../assets/skylimit.JPG'
import robot from '../assets/Robotics Safety.jpg'

import { maxWidth, minWidth,sizing } from '@material-ui/system';
import { MediaQuery } from 'react-responsive'


    

export const Services = () => (
	
	<div style={{backgroundColor: "skyblue", padding: "2%"}}>
		<audio autoplay="autoplay">
     		<source src={safesong} />     
 		</audio>

		 <MediaQuery minDeviceWidth ={1440}> 
		 
		 <Grid container justify="center">
			<Grid>
				
				<img
					src= {skylimit}
					alt="logo"
					width="100%"
					height={300}
				/>
			</Grid>
			
		</Grid>
		<h1>Our Services</h1>
		<h2>Many companies have since benefited from the services that we have provided.</h2>
		
		<Grid container justify="space-between" spacing={0}>
			
			<Grid item xs={5}>
				<h3><b>The services that we specialize in are as follows:</b></h3>
				- Safety Officer Service and Safety Consultancy
				<br/>
				- Fire Safety Management Consultancy
				<br/>
				- Environment Control Officer Service and Consultancy
				<br/>
				- MOM Accredited Safety Training/
				Safety Risk Assessment Training & Risk Control
				<br/>
				- Confined Space Assessment
				<br/>
				- Confined Space Rescue Preparation
				<br/>
				- Emergency Response Planning Consultancy
				<br/>
				- Business Continuity Planning Consultancy
				<br/>
				- Safety Promotion
				<br/>
				- Hot Work Permit Assessment
				<br/>
				- Noise Monitoring & Control
				<br/>
				- OHSAS 18001
				<br/>
				- Sales of Safety Equipment / Safety Warning Signs
				<br/>
				- Drone Services for Building Maintenance & Safety Inspections
				<br/>	
				- AI/Robotic Safety
				<br/>	
			</Grid>
			<Grid item xs={6}>	
				<br />
				<br />
				<Carousel autoplay dots="bottom">
					<div>
						<img
							src={lifting}
							alt="pictures"
							width = {maxWidth}
							height = {500}
						/>
					</div>
					<div>
						<img
						src={firedrill}
						alt=""
						width = {maxWidth}
						height = {500}
						/>
					</div>
					<div>
						<img
							src = {safetytraining}
							alt=""
							width = {maxWidth}
							height = {500}
						/>
					</div>
					<div>
						<img
							src = {oilandgas}
							alt=""
							width = {maxWidth}
							height = {500}
						/>
					</div>
					<div>
						<img
							src = {manufacturing}
							alt=""
							width = {maxWidth}
							height = {500}
						/>
					</div>
					<div>
						<img
							src = {construction}
							alt=""
							width = {maxWidth}
							height = {500}
							
						/>
					</div>
					<div>
						<img
						src={shipbuilding}
						alt=""
						width = {maxWidth}
						height = {500}
						/>
					</div>
					<div>
						<img
						src={drone}
						alt=""
						width = {maxWidth}
						height = {500}
						/>
					</div>

					<div>
						<img
						src={robot}
						alt=""
						width = {maxWidth}
						height = {500}
						/>
					</div>

				</Carousel>
			</Grid>

		</Grid>
		</MediaQuery>

		<MediaQuery maxDeviceWidth ={1339}>

		<Carousel autoplay dots="bottom">
					<div>
						<img
							src={lifting}
							alt="pictures"
							width = {maxWidth}
							height = {180}
						/>
					</div>
					<div>
						<img
						src={firedrill}
						alt=""
						width = {maxWidth}
						height = {180}
						/>
					</div>
					<div>
						<img
							src = {safetytraining}
							alt=""
							width = {maxWidth}
							height = {180}
						/>
					</div>
					<div>
						<img
							src = {oilandgas}
							alt=""
							width = {maxWidth}
							height = {180}
						/>
					</div>
					<div>
						<img
							src = {manufacturing}
							alt=""
							width = {maxWidth}
							height = {180}
						/>
					</div>
					<div>
						<img
							src = {construction}
							alt=""
							width = {maxWidth}
							height = {180}
							
						/>
					</div>
					<div>
						<img
						src={shipbuilding}
						alt=""
						width = {maxWidth}
						height = {180}
						/>
					</div>
					<div>
						<img
						src={drone}
						alt=""
						width = {maxWidth}
						height = {180}
						/>
					</div>

					<div>
						<img
						src={robot}
						alt=""
						width = {maxWidth}
						height = {180}
						/>
					</div>
				</Carousel>

		<h3><b>The services that we specialize in are as follows:</b></h3>
				- Safety Officer Service and Safety Consultancy
				<br/>
				- Fire Safety Management Consultancy
				<br/>
				- Environment Control Officer Service and Consultancy
				<br/>
				- MOM Accredited Safety Training/
				Safety Risk Assessment Training & Risk Control
				<br/>
				- Confined Space Assessment
				<br/>
				- Confined Space Rescue Preparation
				<br/>
				- Emergency Response Planning Consultancy
				<br/>
				- Business Continuity Planning Consultancy
				<br/>
				- Safety Promotion
				<br/>
				- Hot Work Permit Assessment
				<br/>
				- Noise Monitoring & Control
				<br/>
				- OHSAS 18001
				<br/>
				- Sales of Safety Equipment / Safety Warning Signs
				<br/>
				- Drone Services for Building Maintenance & Safety Inspections
				<br/>	
				- AI/Robotic Safety
				<br/>	

				
				
		</MediaQuery> 
		
		

		
	</div> 
)


import React, { Component } from 'react'
import { PageHeader, Divider, Carousel } from 'antd'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Logo } from './Logo'
import { Home } from './Home'
import { Contactus } from './ContactUs'
import { Services } from './Services'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core'
import { MediaQuery } from 'react-responsive'



const { Text } = Typography

const AntTabs = withStyles({
	root: {
	  borderBottom: '1px solid #ff0000',
	},
	indicator: {
	  backgroundColor: '#ff0000',
	},
  })(Tabs);
  
  const AntTab = withStyles(theme => ({
	root: {
		backgroundColor: '#ffa500 ',
	  textTransform: 'none',
	  minWidth: 72,
	  fontWeight: theme.typography.fontWeightRegular,
	  marginRight: theme.spacing(1),
	  fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	  ].join(','),
	  '&:hover': {
		color: '#000000',
		backgroundColor: '#eb6534 ',
		opacity: 1,
	  },
	  '&$selected': {
		color: '#000000',
		backgroundColor: '#eb4634 ',
		fontWeight: theme.typography.fontWeightMedium,
	  },
	  '&:focus': {
		color: '#000000',
	  },
	},
	selected: {},
  }))(props => <Tab disableRipple {...props} />);
  
  const StyledTabs = withStyles({
	indicator: {
	  display: 'flex',
	  justifyContent: 'center',
	  backgroundColor: 'transparent',
	  '& > div': {
		maxWidth: 40,
		width: '100%',
		backgroundColor: '#635ee7',
	  },
	},
  })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
  
  const StyledTab = withStyles(theme => ({
	root: {
	  textTransform: 'none',
	  color: '#fff',
	  fontWeight: theme.typography.fontWeightRegular,
	  fontSize: theme.typography.pxToRem(15),
	  marginRight: theme.spacing(1),
	  '&:focus': {
		opacity: 1,
	  },
	},
  }))(props => <Tab disableRipple {...props} />);
  
  const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	typography: {
	  padding: theme.spacing(1),
	},
	demo1: {
	  backgroundColor: theme.palette.background.paper,
	},
	
  }));

  function TabContainer(props) {
	return (
	  <Typography component="div" style={{ padding: 0, margin:0}}> 
		{props.children}
	  </Typography>
	);
  }
//padding for border

 function CustomizedTabs() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
  
	function handleChange(event, newValue) {
	  setValue(newValue);
	}
  
	return (
	  <div className={classes.root}>
		<div className={classes.demo1}>
		  <AntTabs value={value} onChange={handleChange} variant="fullWidth" >
  
			<AntTab label="Our Company" style={{ fontSize: '20px' }}/>
  
			<AntTab label="Services" style={{ fontSize: '20px' }}/ >
  
			<AntTab label="Contact Us" style={{ fontSize: '20px' }}/>
  
		  </AntTabs>
		  <Typography className={classes.typography} />
		  {value === 0 && <TabContainer> <Home /> </TabContainer>}
      	{value === 1 && <TabContainer> <Services /> </TabContainer>}
      	{value === 2 && <TabContainer> <Contactus /> </TabContainer>}
		</div>
	  </div>
	);
  }

class Dashboard extends Component {
	render() {
		return (
			<div style={{ padding:'10px', fontFamily: 'Helvetica', backgroundColor:'maroon' }}>
				<PageHeader style={{padding:0,margin:0}}>
				<Logo style={{ backgroundColor:'#00ffea'}}  />
				<CustomizedTabs style={{ backgroundColor:'#00ffea'}} />
				</PageHeader>
			</div>
		)
	}
}

export default Dashboard



import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Carousel } from 'antd';
import { Grid} from '@material-ui/core'

import noaccident from '../assets/noaccidents.PNG'
import ghost from '../assets/song.mp3'
import fear from '../assets/bestsafety.PNG'
import funnypic from '../assets/ghostbuster.PNG'
import { MediaQuery } from 'react-responsive'





export const Contactus = () => (
	
	<div style={{backgroundColor: "yellow", padding: "2%"}}>
		

		<MediaQuery minDeviceWidth ={1440}> 

			<audio autoplay="autoplay">
				<source src={ghost} />     
			</audio>

			<Grid container justify="space-evenly" >
				<Grid item xs={5}>
				<img
					width="100%"
					alt="logo"
					src= {noaccident}
					
				/>
				<br/>
				<br/>
				<img
					width="100%"
					alt="logo"
					src= {funnypic}
				/>
				<br/>
				<br/>
				<img
					width="100%"
					alt="logo"
					src= {fear}
				/>
				</Grid>

				<Grid item xs={5}>
					
						<h2><b>Contact us</b></h2>
						

						<h3>Address: <b> (by appointment only)</b></h3>
						
						810 Geylang Road, #03-101, Singapore 409286 
						
						<br />
						<br />

						<h3>Phone Number: <b> (available only during office hours: 9am -6pm)</b></h3>
						
						(65) 6346 7366
						<br /> 
						(65) 67476 228
						<br /> 
						(65) 9108 7806 
						<br />
						<br />

						<h3>Email: </h3>
						<b>sales@avemc.com</b>

						<br />
						
						
						<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfLf2GIxjYOhNz0pjiyITzXJXx1a0SyfxiZrriCaJQyVNiJoQ/viewform?embedded=true"  
						frameborder="0" 
						marginheight="0" 
						marginwidth="0" 
						style={{display: 'flex'}}
						height="100%"
						width ="100%"
						>
							Loading...
							
						</iframe>
						
						<br />

				</Grid>
				
			</Grid>	
		</MediaQuery>

		<MediaQuery maxDeviceWidth ={1439}>
			<h2><b>Contact us</b></h2>
			<br />

			<h3>Address: <b> (by appointment only)</b></h3>
						
			810 Geylang Road, #03-101, Singapore 409286 
						
			<br />
			<br />

			<h3>Phone Number: <b> (available only during office hours: 9am -6pm)</b></h3>
						
			(65) 6346 7366
			<br /> 
			(65) 67476 228
			<br /> 
			(65) 9108 7806 
			<br />
			<br />

			<h3>Email: </h3>
			<b>sales@avemc.com</b>

			<br />
						
						
			<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfLf2GIxjYOhNz0pjiyITzXJXx1a0SyfxiZrriCaJQyVNiJoQ/viewform?embedded=true" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
			<br />

      	</MediaQuery>


	</div>
)

